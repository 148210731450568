import React from "react";

function Footer() {
  return (
    <>
      <section className="cta | margin-top-900 padding-block-900 bg-accent-400 text-neutral-100">
        <div className="container">
          <div className="even-columns vertical-align-center">
            <div>
              <p className="fs-primary-heading fw-bold">
                Simplify your technology today.
              </p>
            </div>
            <div className="justify-self-end-md">
              <a
                className="button"
                data-type="inverted"
                href="https://calendly.com/dgoldshore-rps/rps-introduction?month=2023-04"
                target="_blank"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </section>

      <footer className="primary-footer padding-block-700 bg-neutral-900 text-neutral-100">
        <div className="container">
          <div className="primary-footer-wrapper">
            <div className="primary-footer-logo-social">
              <a href="#" aria-label="home">
                <img src="/images/logos/logo-white.png" width="160" />
              </a>

              <ul className="social-list" role="list" aria-label="social links">
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCC2AJGqzIEHE_WYI2kcKnLg"
                    target="_blank"
                  >
                    <img src="/images/icons8-youtube-48.png" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/rightclick-professional-services/"
                    target="_blank"
                  >
                    <img src="/images/icons8-linkedin-48.png" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="primary-footer-nav">
              <nav className="footer-nav">
                <ul
                  className="flow"
                  style={{ "--flow-spacer": "1em" }}
                  role="list"
                  aria-label="Footer"
                >
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="#">Our Services</a>
                  </li>
                  <li>
                    <a href="/about-rps/">About Us</a>
                  </li>
                  <li>
                    <a href="/resources/">Resources</a>
                  </li>
                  <li>
                    <a href="#">Careers</a>
                  </li>
                  <li>
                    Support Methods
                  </li>
                  <li>
                    P: 646-993-2011 
                  </li>
                  <li>
                    E: <a href="mailto:support@therightclick.com">support@therightclick.com</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="primary-footer-form">
              <form action="">
                <input type="email" placeholder="Let's get in touch..." />
                <button className="button" data-shadow="none">
                  Go
                </button>
              </form>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
